import { beholder } from './beholder'
import { MD5 } from 'crypto-js'

const hashEmailMD5 = (email: string) => {
  return MD5(email.trim().toLowerCase()).toString()
}

enum PIXEL_BREAKPOINTS {
  MOBILE = 768,
  TABLET = 1024,
}

const getDeviceType = () => {
  try {
    const { innerWidth } = window

    if (innerWidth <= PIXEL_BREAKPOINTS.MOBILE) return 'm'
    else if (
      innerWidth > PIXEL_BREAKPOINTS.MOBILE &&
      innerWidth <= PIXEL_BREAKPOINTS.TABLET
    )
      return 't'
    return 'd'
  } catch (err: any) {
    return 'd'
  }
}

export const criteoTrackerScript = `
  window.criteo_q = window.criteo_q || [];
  window.criteo_q.push(
    { event: "setAccount", account: ${process.env.NEXT_PUBLIC_CRITEO_ID} },
    { event: "setSiteType", type: "${getDeviceType()}" },
  );
`

const convertToCriteoCurrency = (value: number) => {
  return (value / 100).toFixed(2)
}

export const criteoTrackerSetup = () => {
  // Beholder functions
  beholder.watch('setUser', user => {
    window.criteo_q.push({ event: 'setEmail', email: hashEmailMD5(user.email) })
  })

  beholder.watch('trackPageView', payload => {
    payload.path === '/' &&
      window.criteo_q.push({
        event: 'viewHome',
      })
  })

  beholder.watch('listView', payload => {
    window.criteo_q.push({
      event: 'viewList',
      item: payload.items.slice(0, 3).map(item => item.sku),
      category: payload.name,
    })
  })

  beholder.watch('productPageView', payload => {
    window.criteo_q.push({
      event: 'viewItem',
      item: {
        id: payload.sku,
      },
    })
  })

  beholder.watch('finished', payload => {
    const { experimentGroup } = payload
    const items = payload.items.map(item => ({
      id: item.sku,
      price: convertToCriteoCurrency(item.price),
      quantity: item.quantity,
    }))

    window.criteo_q.push({
      event: 'trackTransaction',
      id: payload.orderNumber,
      item: items,
      experiment_group: experimentGroup,
    })
  })

  beholder.watch('checkoutInitiated', payload => {
    window.criteo_q.push({
      event: 'viewBasket',
      item: payload.cartItems.map(item => ({
        id: item.sku,
        price: convertToCriteoCurrency(item.price),
        quantity: item.quantity,
      })),
    })
  })
}
