/*
                                                                  `:++;
                                                                 ,#n++z+.
                                                                .znnz;,i#
                                                                *nnn::i*+.
                                                                +nn*,+,ii`
                                                                #nxi;*#*:
                                                                #nnz;+*i.                                 `:ii,
                                                                ixMxxn+,                                 .i#i;#;
                                                                ;xMWMx.                                `izz..*i#`
                                                                ;xWMMx`                                *nn*,*iii`
                                                                *xMMxx.       `.;:`                   ,nxn*:*,*:
                                                                *nxxxx.     .:,.,*+`                 `zxMx#,*,;`
                                                                +xMxxx.    .*i+;;,#;                 ixMWMni,:;`
                                                                +xMxxx,    ;i*+**:*i                 nxMMMMn##,
                                                                zxxxxx,    ii:*#,.#;                 zxnxMn,.`
                                                                nnxxMx:   `#z;::,iz.                 zxxxxi
                                                               `xxxMMx:  ,+nnn+*+n:                  znzMM:
                                                               `xxxxnn#+znMMxnxxnz`                  zx#xM;
                                                              `:nnxnz#znxMnMn#xMz,                   zxxnxi
                                                            .+xMMnz#znxMMMxMMWM#.                    #xzxM+
                                                           ixWMxnnnxMMxxMWMMMMi                      +n+xnn
                                      ..                 `iMWMxxxxMMMMxMMMM#:.                       #z#xnx`
                                    .#xxz,               +MWxxMMMxxMMMMMn;`                         `nzznxx`
                                   `z+;,;z.             ;MMMxMMWxxMMxMMM,                           :z#nnxx`
                                   ;**+ii;*            .xWMMxMMMMxMxxxxn#.                         :#zz#xxn
                                   i;;+#;;+            #WWMxxMxWznzzznnnnz;`                   .;*#znnznxx*
                                   ,;:*z*i+           .MWWMxMxMi +zznxxnnnn+.               .i+znnzznnnxxx.
                                    #;i**#:           iWMWMxMxn  `#zznxxxnnnz:           `:*zznnnzznnnnxx*
                                    in++n#            #WMMxxxx*   .znnzMMxxnnn*         ,+zxnznzznnnznnn#`
                                    inxWW:            zWMMxxxM:    .znnzMMxxxnz*`     `*nnxznnxxxxnxnnzi`
                                    ;zMWM`            nMMMnxMM:     `#nxxMMxxnnz+    ,zxMxnnxxxnxxxzzi`
                                    ;nMWM,           `xMMMnxMM;      `+nxMxxxxxnn;  ixMWMxxMxMxMxnn*`
                                    :nMWW+           `xMMMxxMM*       `zxMnMxMxxnn`iMWWMMMMMxxWMM+.
                                    .nxWWx,          `nxMMxMMMn`       .xMxMMWWxxM#xMWWMWWMMMWWn,`:;:.;#z*.
                                     znMMM#           znxMnMWMxi        +MMxxMMxxxMxxMWWWWWW@@#``*zzxxi,:+x,
                                     ixMWWx;          +nnWxxMWMx,       ixnxxWxnxMxxxxWWWW@@Wi .#nnnM;:::,*#                .,`
                                     .xnWWWni`        ;nzMMxMWMMx`      inMMMWnxMMWMxMWWW@@W; `zxnnxz****:.n.             :#++#:
                                      #xMMMznz*;i:,`  `##xWxnMWxxn,    .nnMMxMxxM@@WMWWMW@W;  iMxnnM#i#M;.`z,            :+,,i:#`
                               `.     `xxWMWMxnxz#zz+i;#znMMMMnMMxx:;z#nnnxxnnnxMx@WW@WW@Wz   zMMnxMz;i#;``z.           .z;*;;i;:
                            `i#znz*i;. :MMWWxxMxxxnnnnznxnxxnxnxnnxxnnxnnnnnnnznnxMMW@WWWM*   xMMxnxW***i.;z            .z*+#::;i
                          `+nnxnxxxxxx#.:MMMWWMxxxxxMMMnnnnxWWMxnnnxxxxxnxzzznnxnxxnMW@WMM,  `xxMxxxMM*:,ix*            :n+**,:i:
                          +n*:inxMMMMMMx:.z@WMMMMMMMMMxxMnzxWWMxMzMWnnnnnxnnnnnnnMxnMnMWxz  `zxxWMMMxMMxxn#.     ,;i,   +x#i;:,+`
                         ,z:::,;xMWWWWMMM: ,+xWWMMMWWWMMWMnnxMMxnxxxxz#nnz#z#+zxnnnxnnMMMx;,x@WWWMMxMMMxnz;     **iizi `nMnz*i;`
                         ii,ii;;nMMMWMWMMx.   ,#MMMxMMWMWMMxMxxxxxMxxzn#++###xn#nnnxx#MMMMMMW@@MWWMMMMMxz#`    ;#*;*;z,iMMxz#`
                         i;.in;*n:`.zxxMWWz     ,#MxxxMWMMxxMMxxxMMxxnnxMxxMMxxxxxxnxnMMxMMnnMWWWWMMMMxnz:     n#zWii*znnnzz.
                         .+:;*++n`   zxxMWW;      `inMMMnxnMWMnxMMMMMxxxMxxMMWWMxxxnMxxMnMMnxMMM@WWMxxx+.    `#Mnz*::+zn#z+i
                          ,:;i**.    .nnMMW#,       `nxnMMxMWxxMxMWMMnzi,..,;izxMMMnxMxMxMMxMMWWWWWWxxi.``  `+WWM+*:;nnn++#`
                            .`.       inxWWnz#i.    ,xxMWMxMMxMxxM@x#*;,`````.:;*xMxnxxxxMMMMMMWWMW@WWMnzzi*nxMWWx++nxxz+z;
                                      `zzxWxznnn#**#xxxMMMMMxxnnMW#i;;i,..````,;:;#MnnxMMMxMMMMWWWWWMxz#zxnnxxMMMxnnxxzzz#`
                                       ;zzMxnznxxnnnMxMxMMMMMMxMW+;i;;;;:,,,..,:::;+MxzMMnnxxMMWWMxz##znxnnxMMnxxnnMxnzzn,
                                        *znxxxnxxxMMnMMMMxxMMxM@+;;;;:iz*;;;*;::::;:#WxxxxnnMMxMx#+#nnxxMMMMMxxnnnxxnnzn;
                                        `+nxMMxnxxMMMxMMMMnzxMWn;:i;;;nn+ii*+z;:::::;xMxxxxnxMxxMMxnxMWWWMMxxxxMxxnnznn*
                                         .#xxMMxnxxWxxnxxzxxnMWi::;;;zxz+##i*+z;:,:,,iWxnnxnxMnznMMMM@@MMxxxMxxnxnznxni  `
                                          :#nxxxxxMMxxnnnzxxMWn:::;;*xz#*MW+i*+#:,,:,:xMnxnnxMxzMxxxxMMxxnznnxnnxnxxz: ;zzi
                                           :zxMxMxMznxMnWznnx@*;i:::#z+*zWWM+i*#:,,,,:*WxnnnMxnMxxnnnxMxznnxxnznxxn+` in*ii;
                                          `:nnnxxnxnnnMxMn##xW;:;,`.:+**xWWW+i;:,``,,::WMznnxMxxnxnxxxMMnxnnxxxnn+.   z*i;*;,
                         ;#zz;`         `*xMMMzzxxnxxxz#Mn##xW;;:.``,iiiMWWWx*i,.``,,::nMnxnnxnxxnxxxxMWxxxnnnn#:    `n*+i*;i
                        **;iin+`      `*xMMWWWMxxnnxxxMnnxz#xM;:i. `.;i*WWWWMi:.```.:::zMxxnnzxznxMxxxxMxnxn#i,      `n+i;:;:
                       .#:++#*Mz`    ;xMWWWWMxnnnnnn#zxnnz#zMWi;;.``.;*+WWWWW*;,.``.::;zMnnz#n#zzxxxMnxxMn*.         ,Mz;::i.
                       :*.:#i+xWni,;zMWW@WWWxzxnxxxnz#zx+z#zxMxn#i`.,i*+WWWWW*i:..`.;izxxxxxxnnzznxxMnxx*`           ;WMn++,
                       .+:;z*#MWWWWW@@@@@WW+`:xMxnxnxxnn+zzzxnnxxxxnnnnzWWWWW##++++#nnxxxnnnxn#nnxnzxxxnz            `Mxnz.
                        *i:;*zMMWW@@@@@WWn,  :nnxx#zzxnWxnzxxMxnnxxxxxxnnxxxxnnxxxnnnzxMxznxxMxnnz+nxxnnn,           `xxnz`
                         ;+*znnxxMWWWWWW+`   ;nnnnxznxnnxnxxnxxnMxnnnxxxxxxxxxxnnznnzxnxMxnxxxMz#nnx#nznxz,          :Mxx#
                          ``.:+zxWWWWW#,     zxnnzMxxxxMMxMxxxnnWMxnxMMMMMMMMxMxxnnxzxxnnMznnxxxzzznznnnxnn.        `nMxx;
                              `,;i**:`      .nMx##nnznMnxnnxnxnxWMznxMWMMMxMWMxxxxxWnnxznnxnxxxMxnxnznMMxMxz.       +Mxxz`
                                           `#nxxzzxxnMxnxnxnxnnxxnMMMWMMMMxnWMxxxnzWnnxxxxxnnnxMxxxxzzMMMMMMn:     *xxnni
                                          `+MxxxMxnMMMxzxnnnxnzxxzxMMMMMMMxMMMxxnzxxzxMznxx#xnxxnMMnxnxMznxMMM#` `*xMxn#`
                                          *nxMxxMnxMMWxxnMnxxnxn#zznnnMMWWMnnz###xMz+xnzzxnnnnxxxMMxnxxxzxxxnnn*;zxxnn+`
                                         ,nxMxMxnnMxMWxxxxxn#+z+;i###xnMMxnnn##*znznnznzn+#zznxMMMMnxxxWMWWxxxMxnxxxn+`
                                        ,nnzxMMMnnnMMW@Mz##*;iz+:;;*+#i*nxzn*;:;z+ii##*:++*+##+MMMxnWnMWMWMzznnxxxxn+`
                                       ,nnxxxMMWMzxxMW@#z**+,;++,,,i#:::,##i:,,,*;:;**,.i*.,i,,xxWMnMznWMWWxnn#xxnzi
                                      :nnxnxMMWWxznMxW@i+*,i.;+n.,.+z,,..zM,,,,,;;,.:#,.*;..:,.zxWWnxMMMWMMxnzzzz+.
                                     ;nnxnxMMWWWxznMxW@:i*,;.;+M,..#M,...MM,.,..;i,`;#.:+;..,,`zMMWzzMWMxMMxnznx+
                                    innxxxMMMWMxMn#WxW@,;i.;.:+Mi..#M:..;MM,.,..zi,`*z,*xi.,.:`xMMMxznWMMMMMxzzz:
                                   ;#+znxxMMMMxnMxzWxM@::*,;;,+Mn..+Mi..+MM:...:Mi,`zn,nMi.,.;`MMMMxnzWMMMMMMxnzi`
                                      `i#xxMMWxnMxzWnMW*,i#:x:*MM*.*M+..zMM:...+Mi,.xn:MMi`:.:,MMMMz#nMMMMxxxxxnnz,
                                         `..nnnxMMnMnMMn.;MiMn*Mxx;iMn..nMM;.,.xMi,ixxxMMi`:;;iMxMM#zMxxMxxxxi;ii;`
                                           ,nxnx@MzxnMxW;,MWWMMxxxxzMM,.xMx;.,;xMi.zxxMMMi`i++zWxMM#MWnMMxxn,
                                           *nxnMMxnznxx@W;xMWMxxxxxxxx*,xxx:..zxx*;xxxMMM*,xxMnMxxM+xMWWMxxn+`
                                          `nnxxnznzzzxM@WxMMWxxxxxxxxxz*xxx,.#xnnzzxMxMMx+;xxMnMxxx+nWxMMxn#z;
                                          :zxxxnMn#zznxWWxxMWxMxxxxxnnnnnxx,*MxxxxxxMxMxMnnxxMxxznx+WMnnzznzz*`
                                           .innMMx#nznxWWxxxWxMxxxxxxnnnnxn+xxxxxxxMMxMxxxxMxMxxzznnWWMn#zzz`
                                            *zMMMMzzznxW@xxxWxMxxxxxxxxnnnnnMxxxxxxMMxMxMxMMxWxzn#z#MWMxnnz+,
                                            i#*#Mn#zznxW@xnnWMMxxxxxMMMxxnnxMxxxxxMMxxMxWMxMxMnzn#zzxni+*;.
                                               *zzzz#nnM@xnnWMMMxnxMMMMWMMMMMxxMnxMMMxMMWWMMnMnznzz#ni
                                               zn#nxnnnM@xxnWMMMxxMMMMMWWWWWWWWMxnxMMxMMWWMMnMnznnz#ni
                                              .xxzzxnnnM@xxxMWWWMWWMMMMxMWW@@WWWMMxMMxxMWWMMnMnnnxzzn*
                                              ,nnnzzznnM@MxxWWW@@@MMMMMMxMMWWWMMMWWMMxxWWWMMnMxnzxzzn+
                                               .nn#nznnx@MMMWWW@@WMMMxxxMMMWMMMMMMW@WMMWWWMMnxnnzn#+nz.
                                               ixn+xznxx@WMW@@W@@MMMxxxxxMMMMMMxxMMW@WMWWWWxnxnnzzz+zn.
                                               #xn#n#nxx@WWW@@W@WMxxxxxxMMMMMMMxnnxW@@MMWWWxxxzx#zzz#:
                                               *z;n+#zxxWWW@@@W@WxxxxxxMMMMMxxxnnMMW@@MMWMzxxxzx#znz#i
                                                 .###zxxWWW@@@W@WxnxxxxMMMxxxxxxMMW@@@MWWz:MMxnnznz#z+
                                                 :zzzzxxWWW@@@@WWzzznxxxMxnxxxxMMW@@@@MW@#.xMxnnzxzzz*
                                                 *x#n#xxMWMM@@@W@n#nzznnxxxnxxxW@@@W@WMWW+.#Wnxzznznz#
                                                `zMnnzxxMWx.W@@WWMz#zz##nnnnnxxxWWWW@WziW*.*Wnx+znnnnn,
                                                ,nxMnznxWW+.n@@@WWn##nz###zz#zznnxxMWWz.xi,;WxM#znnnxx#`
                                                ;nxMMzzMWM:,+@@@WWWn##nxnnnznnnnnnnzzxn.i*,iWnxzznznxxn*
                                                +xMMMzzWWx.,iW@@WWWWnzznnnnnnxn#nn#++nn,.i.+Wxxzz*  :#nz.
                                                zMM#MnnMMx.,;WM*@WW+Wxxnnnnxxxxnzn#+inn,`:.z@MM#x:    ,i;
                                                zz,,#.+xWn,:ix+:@@*.M@Wxxxxnnnxinnx+,zn,.::x@MMni
                                                .     ,nWx:;*n;:nW..n@@WM#Mxxx#.zMM*.+#:,;nxMMxz`
                                                       inx***M,:*+..z@@@#:@WWW;.*WW:.;*;,*znnMz,
                                                       `nxnxxM,:i;.,i@@W,.W@@W,.:Wn..,#i:+*nxz;
                                                        inxMxx,,+;.,,M@#,.n@@x,,.M+.,:x###ixz+
                                                        `znxMM;;#:,:;x@:,,+@@+,,.x*,,;n+zz#n+`
                                                         .zxMx+i+i,:iMM,::i@M*i;:M#:;*+#z#n+`
                                                          :nMxx###:;;xn:i;;@M+*;#@i:;#i++xz.
                                                           ,nxxnzzzzznxi;*#MM#**nMxzx#*+nz.
                                                            .zxxxMnznzMMMMMWMWMMnnMMxz++:
                                                             `inxxxnxxMMMMMMMMMxnxMxn+:
                                                               `;znxMMWWMMMMMMxxMxn*.
                                                                 .+nxxMxxxMMxnMMnz,
                                                                   :zxxxxxxMxxMM#.
                                                                    `+Mxxxxxxxx;
                                                                      *MxMMMxx,
                                                                       ixMMMMi
                                                                        `:ii:
*/

import { captureException } from '@lojinha/sentry'
import mitt from 'mitt'

const ssrMitt = () => ({
  watch: () => null,
  shot: () => null,
})

type StructuredEvent = {
  category:
    | 'landing_page'
    | 'checkout'
    | 'acessibility'
    | 'navigation'
    | 'product_lifecycle'
    | 'change_facelift_old'
    | 'customer_referral'
    | 'login'
    | 'my_purchases'
    | 'my_profile'
    | 'loyalty_landing_page'
    | 'alcoholic_drink_alert'
    | 'cellphone_validation'
    | 'localization_modal'
    | 'unavailable_products_modal'
    | 'add_to_cart'
    | 'onboarding'
    | 'search_global'
  action: string
  label?: string | null
  property?: string | string[]
  value?: number | null
  experimentGroup?: string | null
}

type User = {
  id?: string
  email?: string
  name?: string
  phoneNumber?: string | null
  city?: string | null
  state?: string | null
  country?: string | null
  zipCode?: string | null
  facebookId?: string | null
}

type BeholderEvents = {
  setUser: {
    id: string
    email: string
    name?: string | null
    phoneNumber?: string | null
    city?: string | null
    state?: string | null
    country?: string | null
    zipCode?: string | null
    numberOfOrders?: number
    cartItems: Array<{
      quantity: number
      sku: string
      price: number
    }>
    cartSubTotal?: number | null
    insideLoyaltyProgram?: boolean
    loyaltyTierId?: string
  }
  paymentMethodSelected: {
    method: string
    brand?: string
    experimentGroup?: string
  }
  couponFilled: {
    nameOfCoupon: string
    category: string
    status: 'accepted' | 'rejected' | 'pendent'
    rejectedReason?: string
  }
  finished: {
    orderNumber: number
    total: number
    deliveryTax: number
    deliveryPeriodId: string
    items: Array<{
      quantity: number
      sku: string
      name: string
      kind: string
      price: number
    }>
    coupon: string | null
    numberOfOrders?: number
    experimentGroup?: string
    user?: User
  }
  trackPageView: {
    path: string
    experimentGroup?: string
    user?: User
  }
  openChat: null
  chatOpened: null
  chatClosed: null
  localizationEmailLead: {
    email: string
    address: {
      state?: string
      city?: string
      neighborhood?: string
      zipCode?: string
    }
  }
  structuredEvent: StructuredEvent
  heroCaption: {
    captionName: string
    captionIndex: number
    action: string
  }
  addToCart: {
    action: 'add' | 'remove'
    item: {
      id: string
      name: string
      price: number
      quantity: number
      category: string
    }
    isFirstBuy: boolean
    cartItems: Array<{
      id: string
      quantity: number
      price: number
    }>
    list?:
      | 'categoria'
      | 'lista'
      | 'carrossel'
      | 'checkout'
      | 'busca'
      | 'cart_recommendation'
    positionInList?: number
    cartSubtotal?: number
    experimentGroup?: string
    displayName?: string
    freeDeliveryThreshold?: number
    user: User
  }
  cartCreated: {
    item: {
      id: string
      name: string
      price: number
      quantity: number
      category: string
    }
    isFirstBuy: boolean
    cartItems: Array<{
      id: string
      quantity: number
      price: number
    }>
    list?:
      | 'categoria'
      | 'lista'
      | 'carrossel'
      | 'checkout'
      | 'busca'
      | 'cart_recommendation'
    positionInList?: number
  }
  itemView: {
    id: string
    name: string
    listName: string
    price: number
    positionInList: number
    isAvailable: boolean
  }
  listView: {
    id: string
    name: string
    items: Array<{
      sku: string
      name: string
      price: number
      url: string
      imageUrl: string
    }>
  }
  productPageView: {
    sku: string
  }
  productDetailView: {
    id: string
    name: string
    listName: string
    price: number
    positionInList: number
    isAvailable: boolean
    user: User
  }
  checkoutInitiated: {
    numberOfOrders?: number
    cartItems: Array<{
      quantity: number
      sku: string
      price: number
      name: string
    }>
    cartSubTotal?: number | null
    user: User
  }
}

const beholderConstructor = () => {
  const mittEmitter = mitt()

  return {
    shot: <T extends keyof BeholderEvents>(
      name: T,
      payload: BeholderEvents[T]
    ) => {
      try {
        mittEmitter.emit(name, payload)
      } catch (err: any) {
        captureException(err)
      }
    },
    watch: <T extends keyof BeholderEvents>(
      name: T,
      handlerFunction: (payload: BeholderEvents[T]) => void
    ) => {
      mittEmitter.on(name, (payload?: BeholderEvents[T]) => {
        try {
          handlerFunction(payload!)
        } catch (err: any) {
          captureException(err)
        }
      })
    },
  }
}

export const beholder = process.browser ? beholderConstructor() : ssrMitt()
